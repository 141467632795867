<template>
    <div class="middleFeature">
        <el-carousel height="365px" arrow="always" indicator-position="none" :autoplay="false">
            <el-carousel-item v-for="num in Math.ceil(items.length / 4)" :key="num" class="flex car-item">
                <div v-for="(item, index) in items.slice((num - 1) * 4, num * 4)" :key="index" class="flex-1 text-center select-item" @click="goClick(item)">
                    <img class="banner-img" :src="item.img" alt=""  />
                    <div class="fs-18 fc-333 fw-bold title">{{ item.title }}</div>
                    <div class="fs-14 fc-999 desc">{{ item.desc }}</div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    props: {
        type: { // type === 'beian' 则说明是备案页过来的
            type: String,
            default: ""
        }
    },
    data() {
        return {
            items: [
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/70921e3e1cc2d16a348a1d643569f26b.png',
                    title: '关键词卡首屏',
                    desc: '输入相应的关键词和商品链接，会免费生成一个二维码。买家通过扫描这个二维码，商品会在搜索结果页比较靠前的位置',
                    url: '/checkNum/firstPause',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/0872557b52c1ebc419ca3f045f698865.png',
                    title: '搜索权重推送',
                    desc: '搜索权重推送原理是通过大数据分析判断该商品在该账号下是否有潜在成交可能性，通过数据算法提高商品在消费者搜索结果页，提升商品的曝光率',
                    url: '/weightpush',
                },
                // {
                //     img: 'https://pic.rmb.bdstatic.com/bjh/eab0b1d8cce489ae65a7602dce74b7ab.png',
                //     title: '猜你喜欢',
                //     desc: '利用淘宝词库表和搜索词的潜在成交值，进行数据统计和分析得出该账号在该搜索词下的权重',
                //     url: '/surmise',
                // },
                // {
                //     img: 'https://pic.rmb.bdstatic.com/bjh/affb4c65f42c6d881e2bbd1be131493b.png',
                //     title: '竞品数据透析',
                //     desc: '查询指定时间段商品下单交易数据信息，商品数据一目了然；能查询任意商品的访客数、访客数来源、支付买家数、日期、下单件数等',
                //     url: '/goodsAnalysis',
                // },
                // {
                //     img: 'https://pic.rmb.bdstatic.com/bjh/a625c9455ef9bc4075ce5cb6938ba2e5.png',
                //     title: '防P图验号',
                //     desc: '可以验地址、性别、日常购买类目、我的足迹、已购买宝贝和是否有评价违规等记录',
                //     url: '/validPicture',
                // },
                {
                    img: require('@/index/assets/traceless.svg'),
                    title: '无痕补单',
                    desc: '用户直接下单不需浏览自己宝贝，安全性较高，不计入访客，转化率会超高，甚至超过100%无搜索权重，只累计基础销量权重',
                    url: '/traceless',
                },
                {
                    img: require('@/index/assets/checkAccount.svg'),
                    title: '照妖镜',
                    desc: '旺旺基础信息验号+消费标签+购物标签+6500万降权黑号数据库+近期购买记录, 防骗子、防狂刷号、防不精准号;',
                    url: '/checkAccount',
                },
                {
                    img: require('@/index/assets/checkAccount.svg'),
                    title: '照妖镜',
                    desc: '旺旺基础信息验号+消费标签+购物标签+6500万降权黑号数据库+近期购买记录, 防骗子、防狂刷号、防不精准号;',
                    url: '/checkAccount',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/0872557b52c1ebc419ca3f045f698865.png',
                    title: '旺旺搜索词推荐',
                    desc: '通过实时查询官方对指定淘宝号推荐的搜索词，获取到淘宝相关的标签',
                    url: '/lexTaobaoTag',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/7e1db531577bb8c4911708e81666cb62.png',
                    title: '旺旺打标',
                    desc: '给指定的买家旺旺打标，强行打标后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送的效果，补单、引流利器！',
                    url: '/marking',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/7e1db531577bb8c4911708e81666cb62.png',
                    title: '拼多多打标',
                    desc: '指定多多号推送商品，打上类目标签，商品标签打标，实现拼多多首页推荐',
                    url: '/pddScreen',
                },
            ],
        }
    },
    created() {
        if (this.type && this.type === 'beian') {
            this.items = [
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/70921e3e1cc2d16a348a1d643569f26b.png',
                    title: '关键词卡首屏',
                    desc: '输入相应的关键词和商品链接，会免费生成一个二维码。买家通过扫描这个二维码，商品会在搜索结果页比较靠前的位置',
                    url: '/checkNum/firstPause',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/0872557b52c1ebc419ca3f045f698865.png',
                    title: '搜索权重推送',
                    desc: '搜索权重推送原理是通过大数据分析判断该商品在该账号下是否有潜在成交可能性，通过数据算法提高商品在消费者搜索结果页，提升商品的曝光率',
                    url: '/weightpush',
                },
                // {
                //     img: 'https://pic.rmb.bdstatic.com/bjh/affb4c65f42c6d881e2bbd1be131493b.png',
                //     title: '黑号照妖镜',
                //     desc: '账号查询，查黑号、查降权、查骗子，一查全知晓，4100万黑号数据库降低降权风险，保障商家资金安全！',
                //     url: '/goodsAnalysis',
                // },
                // {
                //     img: 'https://pic.rmb.bdstatic.com/bjh/a625c9455ef9bc4075ce5cb6938ba2e5.png',
                //     title: '防P图验号',
                //     desc: '可以验地址、性别、日常购买类目、我的足迹、已购买宝贝和是否有评价违规等记录',
                //     url: '/validPicture',
                // },
                {
                    img: require('@/index/assets/traceless.svg'),
                    title: '无痕补单',
                    desc: '用户直接下单不需浏览自己宝贝，安全性较高，不计入访客，转化率会超高，甚至超过100%无搜索权重，只累计基础销量权重',
                    url: '/traceless',
                },
                {
                    img: require('@/index/assets/checkAccount.svg'),
                    title: '照妖镜',
                    desc: '旺旺基础信息验号+消费标签+购物标签+6500万降权黑号数据库+近期购买记录, 防骗子、防狂刷号、防不精准号;',
                    url: '/checkAccount',
                },
                {
                    img: require('@/index/assets/checkAccount.svg'),
                    title: '照妖镜',
                    desc: '旺旺基础信息验号+消费标签+购物标签+6500万降权黑号数据库+近期购买记录, 防骗子、防狂刷号、防不精准号;',
                    url: '/checkAccount',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/0872557b52c1ebc419ca3f045f698865.png',
                    title: '旺旺搜索词推荐',
                    desc: '通过实时查询官方对指定淘宝号推荐的搜索词，获取到淘宝相关的标签',
                    url: '/lexTaobaoTag',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/7e1db531577bb8c4911708e81666cb62.png',
                    title: '旺旺打标',
                    desc: '给指定的买家旺旺打标，强行打标后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送的效果，补单、引流利器！',
                    url: '/marking',
                },
                {
                    img: 'https://pic.rmb.bdstatic.com/bjh/7e1db531577bb8c4911708e81666cb62.png',
                    title: '拼多多打标',
                    desc: '指定多多号推送商品，打上类目标签，商品标签打标，实现拼多多首页推荐',
                    url: '/pddScreen',
                },
            ]
        }
    },
    methods: {
        goClick(item) {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }
            this.$router.push(item.url)
        }
    },
}
</script>

<style lang="scss" scoped>
.banner-img {
    width: 200px;
    height: 200px;
}
.select-item {
    flex-basis: 200px;
    // margin: 0 50px;
    cursor: pointer;
    .title {
        text-align: left;
        padding: 20px 0 10px 0px;
    }
    .desc {
        text-align: left;
        padding-left: 0px;
    }
    & + .select-item {
        margin-left: 30px;
    }
}
.car-item {
    width: 100%;
}
</style>

<style lang="scss">
.middleFeature {
    .el-carousel__arrow--left {
        left: 0;
    }
    .el-carousel__arrow--right {
        right: 0;
    }
}

</style>